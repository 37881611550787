import React from 'react';
import { Flex, Heading } from '@cerebral-inc/design-system';
import { DimensionValue } from '@cerebral-inc/design-system/types/types/commonProps';
import { MultiSelectorContentProps } from './MultiSelectorContent';
import { RebrandOnboardingButton } from '../OnboardingButton/RebrandOnboardingButton';
import RebrandYesNoSelector from './RebrandYesNoSelector';
import RebrandMutiSelectorContent from './RebrandMutiSelectorContent';

const RebrandMultiSelector = ({
  question,
  isSingleSelect,
  popUpText,
  selections,
  openPopup,
  onSubmit,
  popUpComponent,
  onOpenPopup,
  onSelect
}: MultiSelectorContentProps) => {
  const { title, options } = question;

  const isYesNoQuestion =
    options.length === 2 && ['Yes', 'No'].every(optionTitle => options.find(option => option.title === optionTitle));

  return (
    <Flex width="100%" background="warmGray100" alignItems="center" minHeight={'calc(100vh - 67px)' as DimensionValue}>
      <Flex
        width={{ base: '100%', sm: 480 }}
        paddingTop={40}
        paddingHorizontal={{ base: 24, sm: 0 }}
        background="warmGray100"
        minHeight={{ base: 'calc(100vh - 156px)' as DimensionValue, md: '100%' }}
      >
        <Heading size="h3" textAlign="center" color="warmGray900">
          {title as string}
        </Heading>

        {isYesNoQuestion ? (
          <RebrandYesNoSelector question={question} onSubmit={onSubmit} />
        ) : (
          <RebrandMutiSelectorContent
            question={question}
            isSingleSelect={isSingleSelect}
            popUpText={popUpText}
            openPopup={openPopup}
            popUpComponent={popUpComponent}
            onOpenPopup={onOpenPopup}
            onSelect={onSelect}
            selections={selections}
          />
        )}
      </Flex>

      {!isYesNoQuestion && (
        <RebrandOnboardingButton
          text="Continue"
          onSubmit={onSubmit}
          isStickyButton
          marginTop={40}
          isDisabled={selections.length === 0}
        />
      )}
    </Flex>
  );
};

export default RebrandMultiSelector;
