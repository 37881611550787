import React from 'react';
import { OnboardingButton } from 'components/Onboarding/shared';
import { OnboardingMobileBodyWrapper } from 'components/Onboarding/shared/OnboardingMobileBodyWrapper';
import { NB } from '@cerebral-inc/ui';
import AssessmentIcon from '../MedsQualifiedPrescribers/assets/assessment.svg';
import { copy } from './constants';

const { Center, Flex, Text, Image, VStack } = NB;

export interface GettingStartedProps {
  currentStep: number;
  handleContinue: () => void;
}

const GettingStarted = ({ currentStep, handleContinue }: GettingStartedProps) => {
  return (
    <Center>
      <OnboardingMobileBodyWrapper>
        <Flex textAlign="center" alignItems="center" w={{ base: '325px', md: '480px' }}>
          <Image src={AssessmentIcon} alt="Assessment Icon" w="40px" h="40px" />
          <Text
            fontSize={{ base: '20px', md: '22px' }}
            fontWeight="semibold"
            lineHeight="32px"
            color="c_black.100"
            mt="8px"
          >
            {copy.title[currentStep - 1]}
          </Text>

          <VStack space="12px" w="100%" mt="24px" mb="40px">
            {copy.steps.map((step, i) => {
              const isActiveStep = currentStep === i + 1;
              return (
                <Flex
                  key={step}
                  flexDirection="row"
                  paddingY="16px"
                  paddingX="12px"
                  borderWidth="2px"
                  borderStyle="solid"
                  borderRadius="8px"
                  alignItems="center"
                  borderColor={isActiveStep ? '#EEECF5' : 'transparent'}
                  backgroundColor={isActiveStep ? 'c_secondary.50' : 'transparent'}
                >
                  <Flex
                    padding="8px"
                    borderRadius="8px"
                    backgroundColor={isActiveStep ? 'c_secondary.200' : 'c_grey.50'}
                    w="40px"
                    h="40px"
                    alignItems="center"
                    justifyContent="center"
                    mr="12px"
                  >
                    <Text
                      fontSize="20px"
                      lineHeight="24px"
                      color={isActiveStep ? 'c_secondary.900' : 'c_grey.800'}
                      fontWeight="semibold"
                    >
                      {i + 1}
                    </Text>
                  </Flex>
                  <Flex alignItems="start">
                    <Text fontSize="14px" lineHeight="20px" color="c_black.900" fontWeight="semibold">
                      {step}
                    </Text>
                  </Flex>
                </Flex>
              );
            })}
          </VStack>
        </Flex>
      </OnboardingMobileBodyWrapper>
      <OnboardingButton text="Continue" onSubmit={handleContinue} isStickyButton />
    </Center>
  );
};

export default GettingStarted;
