import React from 'react';
import { Button, Center, useBreakpointValue } from '@cerebral-inc/design-system';
import { ButtonVariant } from '@cerebral-inc/design-system/types/atoms/Button/types';
import { ColorType, DimensionValue } from '@cerebral-inc/design-system/types/types/commonProps';

export interface RebrandOnboardingButtonProps {
  onSubmit: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  marginTop?: number;
  mobileMarginTop?: number;
  text: string;
  variant?: ButtonVariant;
  isStickyButton?: boolean;
  noBorder?: boolean;
  desktopWidth?: DimensionValue;
  backgroundColor?: ColorType;
  borderColor?: ColorType;
}

export const RebrandOnboardingButton = ({
  onSubmit,
  isLoading,
  isDisabled,
  marginTop,
  mobileMarginTop,
  text,
  variant = 'primary',
  isStickyButton = false,
  noBorder = false,
  desktopWidth = 320,
  backgroundColor = '#F9F7F2',
  borderColor = 'warmGray200'
}: RebrandOnboardingButtonProps): JSX.Element => {
  const breakPoint = useBreakpointValue({ base: 0, md: 767 });
  const isMobile = breakPoint === 0;
  const isStickyButtonOnMobile = isMobile && isStickyButton;

  const generateButton = () => {
    return (
      <Button
        variant={variant}
        onPress={onSubmit}
        isDisabled={isDisabled || isLoading}
        isLoading={isLoading}
        width={{ base: '100%', sm: desktopWidth }}
        maxWidth="100%"
        size="lg"
      >
        {text}
      </Button>
    );
  };

  return isStickyButtonOnMobile ? (
    <>
      <Center
        position={'sticky' as 'relative'}
        bottom={0}
        width="100%"
        paddingHorizontal={24}
        paddingTop={16}
        paddingBottom={16}
        backgroundColor={backgroundColor}
        borderTopColor={borderColor}
        borderTopWidth={noBorder ? 0 : 1}
        marginTop={mobileMarginTop || 0}
        zIndex={100}
      >
        {generateButton()}
      </Center>
    </>
  ) : (
    <Center width="100%" marginTop={marginTop} backgroundColor={backgroundColor} paddingBottom={24}>
      {generateButton()}
    </Center>
  );
};
