import React, { ReactNode } from 'react';
import { Body, Button, Center, Flex } from '@cerebral-inc/design-system';
import CustomChoicePill from 'components/Onboarding/PreSignupFlow/shared/CustomChoicePill';
import { Question } from 'types/question';

export interface RebrandMutiSelectorContentProps {
  selections: string[];
  openPopup: boolean;
  onSelect: (value: string) => void;
  onOpenPopup: (value: boolean) => void;
  question: Question;
  isSingleSelect?: boolean;
  popUpText?: string;
  popUpComponent?({ isOpen, onClose }: { isOpen: boolean; onClose(): void }): ReactNode;
}

const RebrandMutiSelectorContent = ({
  question,
  isSingleSelect,
  popUpText,
  selections,
  openPopup,
  popUpComponent,
  onOpenPopup,
  onSelect
}: RebrandMutiSelectorContentProps) => {
  const { subscript, options } = question;

  return (
    <>
      {subscript && (
        <Body size="small" textAlign="center" color="warmGray900" marginTop={8}>
          {subscript}
        </Body>
      )}

      {!isSingleSelect && (
        <Body size="small" textAlign="center" color="warmGray900" marginTop={subscript ? 0 : 8}>
          Select all that apply.
        </Body>
      )}

      <>
        <Flex paddingTop={34} gap={8} marginBottom={{ base: popUpText ? 0 : 32, md: 0 }}>
          {options.map(option => (
            <CustomChoicePill
              option={option}
              selected={selections.includes(option.name)}
              onSelect={() => onSelect(option.name)}
              key={option.name}
            />
          ))}
        </Flex>
        {popUpText && (
          <Center marginTop={32} marginBottom={{ base: 24, md: 0 }}>
            <Button
              onPress={() => {
                onOpenPopup(true);
              }}
              variant="tertiary"
            >
              {popUpText}
            </Button>

            {openPopup && popUpComponent && popUpComponent({ isOpen: openPopup, onClose: () => onOpenPopup(false) })}
          </Center>
        )}
      </>
    </>
  );
};

export default RebrandMutiSelectorContent;
