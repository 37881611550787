import React from 'react';
import { ChoicePill } from '@cerebral-inc/ui';
import { Option } from 'types/question';
import CheckBox from '@iconscout/react-unicons/icons/uil-check';
import { CheckIconWrapper, ChoicePillWrapper } from './styled';

interface CustomChoicePillProps {
  option: Option;
  selected: boolean;
  type?: 'single' | 'multiple';
  onSelect: (value: string) => void;
}

// will remove after the desygn system is updated
const CustomChoicePill = ({ option, selected, type = 'multiple', onSelect }: CustomChoicePillProps) => {
  return (
    <ChoicePillWrapper selected={selected}>
      <ChoicePill
        key={option.name}
        onSelect={() => onSelect(option.name)}
        choicePillKey={option.name}
        choicePillType={type}
        selected={selected}
        labelText={option.name}
        fullWidth
      />
      {selected && (
        <CheckIconWrapper>
          <CheckBox size={20} color="#fff" />
        </CheckIconWrapper>
      )}
    </ChoicePillWrapper>
  );
};

export default CustomChoicePill;
