import React from 'react';
import { NB } from '@cerebral-inc/ui';
import { Button as DSButton } from '@cerebral-inc/design-system';
import { ButtonVariant } from '@cerebral-inc/design-system/types/atoms/Button/types';
import parseInt from 'lodash/parseInt';

export interface OnboardingButtonProps {
  onSubmit: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  marginTop?: string;
  mobileMarginTop?: string;
  text: string;
  rebrand?: boolean;
  variant?: string;
  isStickyButton?: boolean;
  noBorder?: boolean;
  desktopWidth?: string;
}

const { Button, Center, useMediaQuery } = NB;

export const OnboardingButton = ({
  onSubmit,
  isLoading,
  isDisabled,
  marginTop,
  mobileMarginTop,
  text,
  rebrand = false,
  variant = 'primary',
  isStickyButton = false,
  noBorder = false,
  desktopWidth = '400px'
}: OnboardingButtonProps): JSX.Element => {
  const [isMobile] = useMediaQuery({ minWidth: 0, maxWidth: 767 });
  const isStickyButtonOnMobile = isMobile && isStickyButton;

  const generateButton = () => {
    if (rebrand) {
      return (
        <DSButton
          variant={variant as ButtonVariant}
          onPress={onSubmit}
          isDisabled={isDisabled || isLoading}
          isLoading={isLoading}
          width={{ base: '100%', sm: parseInt(desktopWidth) }}
          maxWidth="100%"
          size="lg"
        >
          {text}
        </DSButton>
      );
    }
    return (
      <Button
        variant={variant}
        onPress={onSubmit}
        isDisabled={isDisabled || isLoading}
        isLoading={isLoading}
        w={{ base: '100%', sm: desktopWidth }}
        maxW="100%"
        h="56px"
        _text={{
          fontFamily: 'Poppins',
          fontSize: '14px'
        }}
        _disabled={{
          opacity: 1
        }}
        id="onboardingButton"
      >
        {text}
      </Button>
    );
  };

  return isStickyButtonOnMobile ? (
    <>
      <Center
        position="sticky"
        bottom={0}
        w="100vw"
        px="24px"
        py="16px"
        backgroundColor={rebrand ? '#F9F7F2' : 'c_white.900'}
        borderTopColor="#d9d9d9"
        borderTopStyle="solid"
        borderTopWidth={noBorder ? 0 : '1px'}
        mt={mobileMarginTop || {}}
      >
        {generateButton()}
      </Center>
    </>
  ) : (
    <Center w="100%" mt={marginTop}>
      {generateButton()}
    </Center>
  );
};
