import React, { useState, useEffect, ReactNode } from 'react';
import { Question } from 'types/question';
import segment from 'utils/segment';
import xor from 'lodash/xor';
import { useFeatureFlags } from 'features';
import { getSegmentEvent, getSubmitSegmentEvent } from '../../helpers';
import { iterableIntegrations, segmentIntegrations } from '../../utils';
import RebrandMultiSelector from './RebrandMultiSelector';
import MultiSelectorContent from './MultiSelectorContent';

export interface IMultiSelectorSingleStackProps {
  question: Question;
  submitAnswer: (answer: string, question: Question, skip?: number) => void;
  isSingleSelect?: boolean;
  popUpText?: string;
  popUpComponent?({ isOpen, onClose }: { isOpen: boolean; onClose(): void }): ReactNode;
}

export const MultiSelectorSingleStack = ({
  question,
  submitAnswer,
  isSingleSelect,
  popUpText,
  popUpComponent
}: IMultiSelectorSingleStackProps) => {
  const [selections, setSelections] = useState<string[]>([]);
  const { name, options } = question;

  const [openPopup, setOpenPopup] = useState(false);
  const { data: flags } = useFeatureFlags();

  const ContentComponent = flags.growth_brand_redesign_v1 ? RebrandMultiSelector : MultiSelectorContent;

  useEffect(() => {
    const segmentName = getSegmentEvent(name);
    if (segmentName) segment.track(segmentName, undefined, segmentIntegrations);
  }, []);

  const handleSelectionClick = (clickedSelection: string) => {
    if (isSingleSelect) {
      setSelections([clickedSelection]);
      return;
    }

    if (clickedSelection === 'None') {
      setSelections(selections.includes('None') ? [] : ['None']);
      return;
    }

    const newSelections = selections.filter(item => item !== 'None');
    setSelections(xor(newSelections, [clickedSelection]));
  };

  const handleSubmit = (selectedSelection?: string) => {
    // selectedSelection -> rebrand yes no question
    const isSelectionValid = selectedSelection && options.find(item => item.name === selectedSelection);
    const finalSelections = isSelectionValid ? selectedSelection : selections.join('|');
    submitAnswer(finalSelections, question);
    const eventName = getSubmitSegmentEvent(name);
    if (eventName) segment.track(eventName, { answered: finalSelections }, iterableIntegrations);
  };

  return (
    <ContentComponent
      question={question}
      isSingleSelect={isSingleSelect}
      popUpText={popUpText}
      selections={selections}
      openPopup={openPopup}
      onSubmit={handleSubmit}
      popUpComponent={popUpComponent}
      onOpenPopup={setOpenPopup}
      onSelect={handleSelectionClick}
    />
  );
};
