import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'types/store';
import { useFeatureFlags } from 'features';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import { RTEStatusEnum, RTETriggerPointEnum } from 'constants/status.enum';
import { useRedirectToAuth0 } from 'components/UniversalLogin/useRedirectToAuth0';
import { isFromMobile, toMobileCallbackUrl, toWebCallbackUrl } from 'types/universal-login.utils';
import { useRTEConfigs } from 'features/useRTEConfigs';
import { isInNetworkState } from 'utils/common';
import Spinner, { SpinnerHolder } from 'components/Spinner';
import { InterstitialProps } from '../types';
import GettingStarted from './GettingStarted';
import RebrandGettingStarted from './RebrandGettingStarted';

export const Progress = ({
  nextQuestion,
  step: currentStep
}: Omit<InterstitialProps, 'offeringKey'> & { step: 1 | 2 | 3 | 4 }) => {
  const history = useHistory();
  const user = useSelector(({ global_reducer: { current_user } }: Store) => current_user);
  const userRegion = user.attributes.patient?.region || '';
  const { data: flags, isLoading: isFlagsLoading } = useFeatureFlags({ user_id: user.attributes.id });
  const { data: rteConfigs, isLoading: isRteConfigsLoading } = useRTEConfigs({ state: userRegion });
  const redirectToAuth0 = useRedirectToAuth0();

  const webOrMobileCallback = isFromMobile() ? toMobileCallbackUrl : toWebCallbackUrl;
  const MainComponent = flags.growth_brand_redesign_v1 ? RebrandGettingStarted : GettingStarted;

  const handleContinue = async () => {
    const obFlow = Cookies.get('ob_flow');

    if (currentStep === 1 && sessionStorage.getItem('anonymous_id')) {
      redirectToAuth0({ returnTo: webOrMobileCallback(), screen_hint: 'signup' });
      return;
    }
    if (
      currentStep === 2 &&
      (obFlow === 'insurance_eligibility_check' || user.attributes.patient?.rte_status === RTEStatusEnum.PRE_RTE)
    ) {
      const isEnabledRteState = flags.insurance_rte_release_state_provider_config
        ? userRegion && rteConfigs.global_rte_enabled && rteConfigs.states?.[0]?.rte_enabled
        : isInNetworkState(userRegion);

      if (flags.insurance_rte_release_onboarding && isEnabledRteState) {
        sessionStorage.setItem('rteTriggerPoint', RTETriggerPointEnum.ONBOARDING);
        history.push('/patient/insurance-coverage');
      } else {
        history.push('/patient/insurance-coverage/unable-verify');
      }
    } else {
      nextQuestion();
    }
  };

  if (currentStep === 2 && isFlagsLoading && isRteConfigsLoading) {
    return (
      <SpinnerHolder>
        <Spinner />
      </SpinnerHolder>
    );
  }

  return <MainComponent currentStep={currentStep} handleContinue={handleContinue} />;
};
