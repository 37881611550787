import { useLocation } from 'react-router';
import { parse } from 'query-string';
import { useSelector } from 'react-redux';
import { QueryToCookieConverter } from 'components/Onboarding/shared/QueryToCookieConverter/QueryToCookieConverter';
import { formatPriceToNumber } from 'utils/formatPriceToNumber';
import { hashValueSha2WithoutSecret } from 'utils/hashValueSha2';
import segment, {
  ACCEPT_HIPAA_MARKETING_AUTHORIZATION,
  ACCOUNT_CREATE,
  CHOOSE_OPTION,
  FUNNEL_COMPLETE_EMAIL_INCLUDED,
  INITIATE_FUNNEL_COMPLETE,
  ITrackSubscriptionParams,
  ORDER_COMPLETED,
  PODSCRIBE_ACCOUNT_CREATE,
  PODSCRIBE_ORDER_COMPLETED,
  FUNNEL_COMPLETE_ADD,
  convertCampaignId,
  mapSubscriptionTypeByOfferingKey,
  FUNNEL_STARTED
} from 'utils/segment';
import { useKetchContext } from 'contexts/KetchContext';
import { Store } from 'types/store';
import { useAnalyticsPlatform } from './useAnalyticsPlatform';
import { BING_ADS, IAnalyticsData } from './useAnalyticsPlatform/types';
import useMountain from './useMountain';

export const ACCOUNT_CREATE_LTV = 25;
export const HIPAA_AUTH_ACCEPTED_LTV = 30;
export const CHOOSE_OPTION_LTV = 35;
export const FUNNEL_COMPLETE_ADD_LTV = 40;
export const INITIATE_FUNNEL_COMPLETE_LTV = 45;

export const standardIntegrations = {
  All: false,
  Heap: true,
  'TikTok Event Sharing (get-cerebral)': true,
  'Facebook Event Sharing (get-cerebral)': true,
  'Atwave Event Sharing (get-cerebral)': true,
  'Datadog Function US (get-cerebral)': true,
  'Google Ads Conversions': true,
  'Slack (Actions)': true,
  StackAdapt: true
};

export const useCoreMarketingSegmentEvents = () => {
  const location = useLocation();
  const { trackCustomEvent } = useAnalyticsPlatform();
  const { trackMountainPage, trackMountainConversion } = useMountain();
  const { purposes } = useKetchContext();
  const hipaaMarketingAuthorization = useSelector(
    (store: Store) => store.patient_reducer.hipaaAuthorizationStatus === 'accepted'
  );
  const zipcode = useSelector((store: Store) => store.global_reducer.current_user.attributes.postal_code);
  const email = useSelector((store: Store) => store.global_reducer.current_user.attributes.email);
  const gclid = new QueryToCookieConverter('gclid').getCookieValue();
  const fbclid = new QueryToCookieConverter('fbclid').getCookieValue();
  const ttclid = new QueryToCookieConverter('ttclid').getCookieValue();
  const awclid = new QueryToCookieConverter('awclid').getCookieValue();

  const trackSignUp = (opts?: { email: string }) => {
    /* When someone clicks an Impact Affiliate Link, and get to our homepage, we save the irclickid
     * into localStorage, we then check if it exist on Account Creation and send it to segment
     * to correctly match the conversion in Impact
     */
    const irClickID = localStorage.getItem('irclickid');
    const referrer = irClickID
      ? {
          type: 'impactRadius',
          id: irClickID
        }
      : undefined;
    const { search } = location;
    const urlParams = parse(search);
    const appsflyerObj = segment.urlParamsToContext(urlParams);

    segment.track(
      ACCOUNT_CREATE,
      {
        gclid,
        fbclid,
        ttclid,
        awclid,
        ltv: ACCOUNT_CREATE_LTV,
        email: hashValueSha2WithoutSecret(opts?.email as string),
        isKetchAdvertisingAnalyticsAccepted: !!purposes.advertising_analytics
      },
      {
        ...appsflyerObj,
        integrations: {
          ...(appsflyerObj?.integrations || {}),
          ...standardIntegrations,
          Iterable: true,
          'Impact Partnership Cloud': true
        },
        referrer
      }
    );

    segment.track(
      PODSCRIBE_ACCOUNT_CREATE,
      { email: opts?.email },
      { integrations: { All: false, 'Podscribe (Actions)': true } }
    );

    if (irClickID) {
      localStorage.removeItem('irclickid');
    }

    trackCustomEvent({ platforms: [BING_ADS], eventName: ACCOUNT_CREATE, ltv: ACCOUNT_CREATE_LTV } as IAnalyticsData);
  };

  const trackHipaaMarketingAuthorizationAccepted = () => {
    segment.track(
      ACCEPT_HIPAA_MARKETING_AUTHORIZATION,
      {
        hipaa_marketing_authorization: true,
        ttclid,
        fbclid,
        gclid,
        zipcode,
        email,
        ltv: HIPAA_AUTH_ACCEPTED_LTV
      },
      {
        integrations: {
          ...standardIntegrations,
          Iterable: true
        }
      }
    );

    trackCustomEvent({
      platforms: [BING_ADS],
      eventName: FUNNEL_STARTED,
      ltv: HIPAA_AUTH_ACCEPTED_LTV
    } as IAnalyticsData);
    trackMountainPage({ eventName: FUNNEL_STARTED, ltv: HIPAA_AUTH_ACCEPTED_LTV });
  };

  const trackSubscription = ({
    offeringKey,
    price,
    campaignId,
    orderId,
    planId,
    patientId,
    isStrongStart,
    planNameAlias,
    ltv,
    subscriptionFlow
  }: ITrackSubscriptionParams): void => {
    let subscriptionType = mapSubscriptionTypeByOfferingKey[offeringKey] || 'Subscription Signup';

    if (isStrongStart) {
      subscriptionType = `Start Strong + ${subscriptionType}`;
    }

    const product = {
      id: subscriptionType,
      name: subscriptionType,
      quantity: 1,
      price: formatPriceToNumber(price, 85),
      patient_plan: `${patientId}-${planId}`,
      plan_name: offeringKey,
      order_id: orderId
    };

    const orderCompletedParams = {
      user: {
        email,
        userId: patientId
      },
      items: [product],
      email,
      type: subscriptionType,
      plan_id: planId,
      patient_plan: `${patientId}-${planId}`,
      plan_name: offeringKey,
      products: [product],
      campaignId: campaignId ? convertCampaignId(campaignId) : null,
      ketch_advertising_analytics: !!purposes.advertising_analytics,
      gclid,
      fbclid,
      ttclid,
      awclid,
      planNameAlias,
      ltv,
      subscriptionFlow,
      hipaa_marketing_authorization: hipaaMarketingAuthorization === true
    };

    segment.track(subscriptionType, orderCompletedParams, {
      integrations: {
        All: false,
        Iterable: true
      }
    });
    segment.track(ORDER_COMPLETED, orderCompletedParams, {
      integrations: standardIntegrations
    });
    segment.track(
      FUNNEL_COMPLETE_EMAIL_INCLUDED,
      {
        email,
        hipaa_marketing_authorization: hipaaMarketingAuthorization === true,
        ttclid,
        fbclid,
        gclid,
        zipcode,
        planNameAlias,
        ltv,
        subscriptionFlow
      },
      {
        integrations: standardIntegrations
      }
    );
    segment.track(
      PODSCRIBE_ORDER_COMPLETED,
      { total: ltv, email },
      { integrations: { All: false, 'Podscribe (Actions)': true } }
    );

    trackCustomEvent({
      platforms: [BING_ADS],
      eventName: FUNNEL_COMPLETE_EMAIL_INCLUDED,
      planNameAlias,
      ltv
    } as IAnalyticsData);
    if (hipaaMarketingAuthorization) {
      trackMountainConversion({ eventName: FUNNEL_COMPLETE_EMAIL_INCLUDED, ltv: ltv || 0 });
    }
  };

  const trackChooseOption = ({
    planNameAlias,
    subscriptionFlow
  }: {
    planNameAlias?: string;
    subscriptionFlow?: string;
  }) => {
    segment.track(
      CHOOSE_OPTION,
      {
        hipaa_marketing_authorization: hipaaMarketingAuthorization,
        gclid,
        fbclid,
        ttclid,
        planNameAlias,
        zipcode: hipaaMarketingAuthorization ? zipcode : null,
        email: hipaaMarketingAuthorization ? email : null,
        ltv: CHOOSE_OPTION_LTV,
        subscriptionFlow
      },
      {
        integrations: standardIntegrations
      }
    );

    trackCustomEvent({
      platforms: [BING_ADS],
      eventName: CHOOSE_OPTION,
      ltv: CHOOSE_OPTION_LTV,
      planNameAlias
    } as IAnalyticsData);
    if (hipaaMarketingAuthorization) {
      trackMountainPage({ eventName: CHOOSE_OPTION, planNameAlias, ltv: CHOOSE_OPTION_LTV });
    }
  };

  const trackFunnelCompleteAdd = ({
    planNameAlias,
    subscriptionFlow
  }: {
    planNameAlias?: string;
    subscriptionFlow?: string;
  }) => {
    segment.track(
      FUNNEL_COMPLETE_ADD,
      {
        hipaa_marketing_authorization: hipaaMarketingAuthorization,
        gclid,
        fbclid,
        ttclid,
        planNameAlias,
        zipcode: hipaaMarketingAuthorization ? zipcode : null,
        email: hipaaMarketingAuthorization ? email : null,
        ltv: FUNNEL_COMPLETE_ADD_LTV,
        subscriptionFlow
      },
      {
        integrations: standardIntegrations
      }
    );

    trackCustomEvent({
      platforms: [BING_ADS],
      eventName: FUNNEL_COMPLETE_ADD,
      ltv: FUNNEL_COMPLETE_ADD_LTV,
      planNameAlias
    } as IAnalyticsData);
    if (hipaaMarketingAuthorization) {
      trackMountainPage({ eventName: FUNNEL_COMPLETE_ADD, planNameAlias, ltv: FUNNEL_COMPLETE_ADD_LTV });
    }
  };

  const trackInitiateFunnelComplete = ({
    planNameAlias,
    subscriptionFlow
  }: {
    planNameAlias?: string;
    subscriptionFlow?: string;
  }) => {
    segment.track(
      INITIATE_FUNNEL_COMPLETE,
      {
        hipaa_marketing_authorization: hipaaMarketingAuthorization,
        gclid,
        fbclid,
        ttclid,
        planNameAlias,
        zipcode: hipaaMarketingAuthorization ? zipcode : null,
        email: hipaaMarketingAuthorization ? email : null,
        ltv: INITIATE_FUNNEL_COMPLETE_LTV,
        subscriptionFlow
      },
      {
        integrations: standardIntegrations
      }
    );

    trackCustomEvent({
      platforms: [BING_ADS],
      eventName: INITIATE_FUNNEL_COMPLETE,
      ltv: INITIATE_FUNNEL_COMPLETE_LTV,
      planNameAlias
    } as IAnalyticsData);
    if (hipaaMarketingAuthorization) {
      trackMountainPage({ eventName: INITIATE_FUNNEL_COMPLETE, planNameAlias, ltv: INITIATE_FUNNEL_COMPLETE_LTV });
    }
  };

  return {
    trackSignUp,
    trackHipaaMarketingAuthorizationAccepted,
    trackSubscription,
    trackChooseOption,
    trackFunnelCompleteAdd,
    trackInitiateFunnelComplete
  };
};
