import styled from 'styled-components/macro';

export const ChoicePillWrapper = styled.div<{ selected: boolean }>`
  position: relative;

  & > div:first-child > div > svg {
    display: none;
  }

  & > div:first-child > div > div {
    color: ${({ selected }) => (selected ? '#443962' : '#393939')};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-wrap: auto;
  }

  & > div:first-child {
    height: 72px;
    padding: 0 40px 0 24px;
    border-radius: 8px;
    border: ${({ selected }) => (selected ? '1.5px solid #443962' : '1.5px solid #d2d4f2')};
    box-shadow: none;
    background-color: #fff;
  }

  & > div:first-child:hover {
    border-color: #8b77c4;
  }

  & > div:first-child:active {
    background-color: #f5f6ff;
    color: #7866a8;
  }

  & > div:first-child:active > div > div {
    color: #7866a8;
  }
`;

export const CheckIconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0px 8px;
  background: #443962;
  width: 32px;
  height: 32px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
