import React from 'react';
import { Body, Box, Flex, Heading } from '@cerebral-inc/design-system';
import { Question } from 'types/question';
import Yes from './images/yes.svg';
import No from './images/no.svg';

const RebrandYesNoSelector = ({ question, onSubmit }: { question: Question; onSubmit: (value: string) => void }) => {
  const { options, subscript } = question;

  return (
    <>
      <Flex marginTop={24} marginBottom={24} flexDirection="row" gap={16}>
        {options.map(option => (
          <Flex
            paddingTop={40}
            paddingBottom={40}
            paddingHorizontal={16}
            width={`${100 / options.length}%`}
            background="#FFF"
            height="100%"
            justifyContent="center"
            alignItems="center"
            borderRadius="small"
            borderWidth={1.5}
            borderColor="#D2D4F2"
            style={{ cursor: 'pointer' }}
            onPress={() => onSubmit(option.name)}
            key={option.name}
          >
            <Box>{option.title === 'Yes' ? <img src={Yes} alt="yes" /> : <img src={No} alt="no" />}</Box>
            <Heading size="h4" color="#353A41">
              {option.title}
            </Heading>
          </Flex>
        ))}
      </Flex>

      {subscript && (
        <Body size="small" textAlign="center" color="warmGray900" marginTop={8}>
          {subscript}
        </Body>
      )}
    </>
  );
};

export default RebrandYesNoSelector;
