import React, { createContext, useState, useEffect } from 'react';
import { withRouter, type RouteComponentProps } from 'react-router';
import variations, { Variation } from './variations';
import { OfferingKey, offerings } from '../../utils/plans';
import { valueFromStorage } from '../../utils/common';

export interface VariationContextProps {
  plan: OfferingKey;
  promo: string;
  variation: string;
  lookupTable: Variation;
  campaignId: string | boolean;
  backendAbVariation: string | boolean;
  country: string;
  iterableCampaignId?: string;
  insuranceReferrer?: string | boolean;
}

const VariationContext = createContext<Partial<VariationContextProps>>({});

const createSearch = (): URLSearchParams =>
  new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '');

const promoFromUrl = () => {
  const search = createSearch();
  const promo = search.get('promo')?.toUpperCase() || null;

  if (promo) {
    localStorage.setItem('promo', promo);
    return promo;
  }

  return search.has('promo') ? 'EMPTY' : null;
};

const planFromUrl = (): OfferingKey | null => {
  const search = createSearch();
  const plan = search.get('plan') as OfferingKey | null;
  const month = search.get('month') as string | null;
  const fsa = search.get('fsa') as string | null;
  if (plan && offerings.includes(plan)) {
    localStorage.setItem('plan', plan);

    if (month !== null) {
      sessionStorage.setItem('month', month);
    }
    if (fsa !== null) {
      sessionStorage.setItem('fsa', fsa);
    }
    return plan;
  }

  if (month !== null) {
    sessionStorage.setItem('month', month);
  }
  if (fsa !== null) {
    sessionStorage.setItem('fsa', fsa);
  }

  return null;
};

const defaultPlan = (): OfferingKey => planFromUrl() || (valueFromStorage('plan') as OfferingKey) || 'medication';

const defaultPromo = () => {
  const promo = promoFromUrl() || valueFromStorage('promo');

  if (promo && !variations.some(v => v.expired && v.variation.toLowerCase() === promo.toLowerCase())) {
    return promo;
  }

  return 'CARE30';
};

const campaignFromUrl = () => {
  const search = createSearch();
  const campaign = search.has('utm_campaign') ? search.get('utm_campaign') : null;

  if (campaign) {
    localStorage.setItem('campaign', campaign);
    return campaign;
  }

  return search.has('campaign') ? 'EMPTY' : null;
};

const defaultCampaign = () => {
  const campaign = campaignFromUrl() || valueFromStorage('campaign');

  if (campaign && !variations.some(v => v.expired && v.variation.toLowerCase() === campaign.toLowerCase())) {
    return campaign;
  }

  return false;
};

const iterableCampaignFromUrl = () => {
  const search = createSearch();
  const campaign = search.has('campaignId') ? search.get('campaignId') : null;

  if (campaign) {
    localStorage.setItem('iterableEmailCampaignId', campaign);
    return campaign;
  }

  return search.has('campaignId') ? 'EMPTY' : null;
};

const defaultIterableCampaign = () => {
  const campaign = iterableCampaignFromUrl() || valueFromStorage('iterableEmailCampaignId');

  if (campaign && !variations.some(v => v.expired && v.variation.toLowerCase() === campaign.toLowerCase())) {
    return campaign;
  }

  return '';
};

const backendAbVariationFromUrl = () => {
  const search = createSearch();
  const backendAbVariation = search.has('abv') ? search.get('abv') : null;

  if (backendAbVariation) {
    localStorage.setItem('backendAbVariation', backendAbVariation);
    return backendAbVariation;
  }

  return search.has('backendAbVariation') ? 'EMPTY' : null;
};

const defaultBackendAbVariation = () => {
  const backendAbVariation = backendAbVariationFromUrl() || valueFromStorage('backendAbVariation');

  if (
    backendAbVariation &&
    !variations.some(v => v.expired && v.variation.toLowerCase() === backendAbVariation.toLowerCase())
  ) {
    return backendAbVariation;
  }

  return false;
};

const insuranceReferrerFromUrl = () => {
  const search = createSearch();
  const insuranceReferrer = search.has('partner') ? search.get('partner') : null;
  if (insuranceReferrer) {
    return insuranceReferrer;
  }

  return search.has('insuranceReferrer') ? 'EMPTY' : null;
};

const defaultInsuranceReferrer = () => {
  const insuranceReferrer = insuranceReferrerFromUrl();

  if (
    insuranceReferrer &&
    !variations.some(v => v.expired && v.variation.toLowerCase() === insuranceReferrer.toLowerCase())
  ) {
    return insuranceReferrer;
  }

  return false;
};

interface VariationProviderProps extends RouteComponentProps {
  children: React.ReactNode;
}

const VariationProvider = withRouter(({ children, location }: VariationProviderProps) => {
  const [plan, setPlan] = useState<OfferingKey>(defaultPlan());
  const [promo] = useState(defaultPromo());
  const [campaignId] = useState(defaultCampaign());
  const [iterableCampaignId] = useState(defaultIterableCampaign());
  const [country] = useState('us');
  const [backendAbVariation] = useState(defaultBackendAbVariation());
  const [insuranceReferrer] = useState(defaultInsuranceReferrer());
  const variation = variations.some(v => v.variation === promo) ? promo : '*';
  const lookupTable = variations.find(v => v.variation === variation);

  useEffect(() => {
    const newPlan = defaultPlan();
    if (newPlan) setPlan(newPlan);
  }, [location.search]);

  const value = {
    plan,
    promo,
    variation,
    lookupTable,
    campaignId,
    backendAbVariation,
    insuranceReferrer,
    country,
    iterableCampaignId
  };

  return <VariationContext.Provider value={value}>{children}</VariationContext.Provider>;
});

export { VariationProvider, VariationContext, createSearch, planFromUrl };
